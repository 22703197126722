import { APIFilter } from '@/utils/api'

export default {
  async selectFichero (Vue, idfichero) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idfichero', idfichero)
    const resp = await Vue.$api.call('fichero.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
}
